.multiselectContainer{ 
	width: 265px;
  }
  
.chips{ 
	color: #000;
  }
 
/* .option{ 
	color: blue;
  } */